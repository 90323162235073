<template>
  <b-row class="match-height">
    <b-col cols="12">
      <b-card no-body>
        <b-card-header>
          <b-card-title>
            Danh sách văn bằng, chứng chỉ
          </b-card-title>
        </b-card-header>
        <div class="m-2">
          <b-row class="match-height">
            <b-col
                cols="3"
                md="3"
            >
              <b-form-group
                  label="Phân loại"
                  label-for="filter.type"
              >
                <v-select
                    v-model="filter.type"
                    :options="types"
                    :reduce="option => option.value"
                    @input="onCredentialTypeChange"
                />
              </b-form-group>
            </b-col>

            <b-col
                cols="9"
                md="6"
                class="text-left d-md-flex align-items-md-start justify-content-md-end"
            >
              <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="primary"
                  class="mb-0 mt-0 mb-sm-1"
                  @click="onSearch"
              >
                <feather-icon icon="FilterIcon" /> Lọc
              </b-button>
            </b-col>
          </b-row>
        </div>

        <b-card-body>
          <vue-good-table
              :columns="columns"
              :rows="credentials"
              :pagination-options="paginationOptions"
              :line-numbers="true"
          >
            <div
                slot="emptystate"
                style="text-align: center; font-weight: bold"
            >
              Không có bản ghi nào !
            </div>
            <template
                slot="table-row"
                slot-scope="props"
            >
              <span v-if="props.column.field === 'status'">
                {{ getStatusName(props.row.status) }}
              </span>
              <span v-else>{{ props.formattedRow[props.column.field] }}</span>
              <span
                  v-if="props.column.field === 'action'"
                  class="thead-group-action"
              >
                <b-button-group>
                          <b-button
                              class="btn-icon"
                              v-b-modal.CredentialOfStudentModal
                              variant="primary"
                              size="sm"
                              title="Tạo yêu cầu"
                              @click="createCredentialCondition(props.row)"
                          >
                            <feather-icon icon="MessageSquareIcon" />
                          </b-button>
                        </b-button-group>
              </span>
            </template>
          </vue-good-table>
        </b-card-body>
      </b-card>
      <b-modal
          id="CredentialOfStudentModal"
          ref="CredentialOfStudentModal"
          ok-title="Xác nhận"
          cancel-title="Hủy"
          centered
          size="xl"
          :hide-header="true"
      >
        <CredentialsOfStudent
            :item="currentCredential"
            :student="user"
            :credential-type="currentCredentialType"
        />
      </b-modal>

    </b-col>
  </b-row>
</template>

<script>
import {
  BButton, BButtonGroup,
  BCard,
  BCardBody,
  BCardHeader,
  BCardTitle,
  BCol,
  BContainer, BDropdown, BDropdownItem,
  BFormGroup,
  BFormSelect, BModal,
  BOverlay,
  BPagination,
  BRow,
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import '@core/scss/vue/libs/vue-good-table.scss'
import { mapActions, mapGetters } from 'vuex'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { getUser } from '@/auth/utils'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import CredentialsOfStudent from '@/views/certificate/CredentialsOfStudent.vue'

export default {
  name: 'SearchCertificate',
  directives: {
    Ripple,
  },
  components: {
    CredentialsOfStudent,
    BDropdown,
    BDropdownItem,
    VueGoodTable,
    BButton,
    BButtonGroup,
    BCard,
    BCol,
    BContainer,
    BFormGroup,
    BFormSelect,
    BOverlay,
    BPagination,
    BCardTitle,
    BCardBody,
    BCardHeader,
    BRow,
    BModal,
    Ripple,
    vSelect,
  },
  props: {},
  data() {
    return {
      isAllowRegister: true,
      isLoading: false,
      user: getUser(),
      filter: {
        type: null,
        name: null,
      },
      currentCredential: undefined,
      currentCredentialType: undefined,
      paginationOptions: {
        enabled: false,
      },
      data: [],
      columns: [
        {
          label: 'Tên',
          field: 'name',
          sortable: true,
          thClass: 'text-center',
          tdClass: 'text-center',
          filterOptions: {
            enabled: true,
            trigger: 'enter',
          },
        },
        {
          label: 'Phân loại',
          field: 'credentialType',
          sortable: true,
          thClass: 'text-center',
          tdClass: 'text-center',
        },
        {
          label: 'Trạng thái',
          field: 'status',
          sortable: true,
          thClass: 'text-center',
          tdClass: 'text-center',
        },
        {
          label: '',
          field: 'action',
          sortable: false,
          tdClass: 'text-center',
        },
      ],
    }
  },
  computed: {
    ...mapGetters({
      types: 'credentialSearch/types',
      credentials: 'credentialSearch/credentials',
      statuses: 'credentialSearch/statuses',
    }),
  },
  mounted() {

  },
  async created() {
    this.filter.type = this.types[1].value
  },
  methods: {
    ...mapActions({
      readCredentials: 'credentialSearch/readCredentials',
    }),
    onCredentialTypeChange(value) {
      this.filter.type = value
    },
    registerCredential(row) {
      this.currentCredential = row
    },
    createCredentialCondition(row) {
      this.currentCredential = row
    },
    async onSearch() {
      await this.readCredentials(this.filter)
      this.currentCredentialType = this.filter.type
    },
    getStatusName(id) {
      return this.statuses.find(status => status.value === id).label
    },
  },
}
</script>

<style scoped lang="scss">

</style>
